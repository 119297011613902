import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '@components/Layout/Layout';
import SEO from '@components/SEO/SEO';
import ResourceQueryProvider from '@providers/ResourceQuery/ResourceQuery';
import ResourceList from '@components/ResourceList/ResourceList';

const Homepage = ({ data }) => {
  const resources = data.allResources.nodes;
  const categories = data.allCategories.nodes;
  return (
    <Layout>
      <>
        <SEO />
        <ResourceQueryProvider
          resources={resources}
          initialSize={30}
          pageSize={30}
        >
          <ResourceList className="mt-3" xs="1" md="2" lg="3" />
        </ResourceQueryProvider>
      </>
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    allCategories: allCategory {
      nodes {
        uuid
        emojiicon
        name
        slug
      }
    }
    allResources: allResource(sort: { fields: [addedAt], order: DESC }) {
      nodes {
        uuid
        url
        title
        description
        price
        freetrial
        addedAt
        localImage {
          childCloudinaryAsset {
            fluid {
              ...CloudinaryAssetFluid
            }
          }
          publicURL
          extension
        }
      }
    }
  }
`;

export default Homepage;
